<template>
  <div>
    <div class="row">
      <div class="col-12">
        <c-card title="기본정보" class="cardClassDetailInfo">
          <template slot="card-button">
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-6 col-md-2">
              <c-label-text title="공사현장" :value="assessPlan.plantName"></c-label-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-5">
              <c-label-text title="평가명" :value="assessPlan.assessmentName"></c-label-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <c-label-text title="평가기간" :value="assessPlan.assessmentDate"></c-label-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-2">
              <c-label-text title="진행상태" :value="assessPlan.approvalStatusName ? 
              assessPlan.approvalStatusName : assessPlan.stepCustomName"></c-label-text>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-tab
      ref="actionTab"
      type="tabcard"
      align="left"
      :tabItems="tabItems"
      :inlineLabel="true"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :height="height"
          :targetData.sync="tab.targetData"
          :assessPlan.sync="assessPlan"
          :param="{ 
            riskAssessmentPlanId: assessPlan.riskAssessmentPlanId, 
            stepCd: assessPlan.stepCd
          }"
          @assessComplete="assessComplete"
          @getList="getList"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-action',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      assessPlan: {
        riskAssessmentPlanId: '',
        plantCd: '',
        assessTypeCd: '',
        assessmentName: '',
        assessmentStartDate: '',
        assessmentEndDate: '',
        assessmentYear: '',
        stepCd: '',
        assessmentManageUserId: '',
        assessmentManageVendorCd: '',
        ramMatrixId: null,
        reviewDate: '',
        approvalUserId: '',
        approvalDate: '',
        sopMapId: '',
        remark: '',
        reviewUserId: '',
        approvalStatusCd:'',
        approvalTypeCd:'',
        approvalStatusName:'',
        sysApprovalRequestId:'',
      },
      editable: true,
      isComplete: false,
      listUrl: '',
      completeUrl: '',
    };
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.assessPlan.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.assessPlan.approvalStatusCd, 
        apprEditable: this.assessPlan.stepCd !== 'RAS000010',
        assessPlan: this.actionData, // 결재 assessPlan
        approvalUrl: this.completeUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000003', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          riskAssessmentPlanId: this.assessPlan.riskAssessmentPlanId,
          isApprContent: true
        },
        approvalRequestName: this.assessPlan.assessmentName + ' ['+this.targetData.assessVendorName+']', // 결재요청명 (문서 title)
        approvalConnId: this.assessPlan.riskAssessmentPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.targetData.processAssessStepCd !== 'RPA0000005'
            }) === -1 
        && this.assessPlan.stepCd === 'RAS000010'
    },
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 50;
      return numHeight + 'px';
    },
  },
  watch: {
    'assessPlan.searchScenario.flag'() {
      this.getList();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      
      // url setting
      this.listUrl = selectConfig.saas.targetVendor.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.popupParam.riskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.assessPlan = this.$_.clone(_result.data)
        this.tabItems = [];
        if (_result.data.vendorList) {
          this.$_.forEach(_result.data.vendorList, item => {
            this.tabItems.push({
              key: uid(),
              name: item.riskAssessmentVendorId, 
              icon: 'work_outline', 
              label: item.assessVendorName, 
              targetData: item,
              component: () => import(`${'@/pages/saas/action/riskAssessActionDetail.vue'}`)
            })
          })
          this.tab = _result.data.vendorList[0].riskAssessmentVendorId
        }
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        assessPlan: stepCd
      })
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (this.actionData.meetList) {
            this.$_.forEach(this.actionData.meetList, item => {
              item.regUserId = this.$store.getters.user.userId
              item.chgUserId = this.$store.getters.user.userId
              item.meetingStartTime = item.meetingTime[0]
              item.meetingEndTime = item.meetingTime[1]
            }) 
          }
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
  }
};
</script>
